import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { api, baseEndpointV1 } from '@/modules/shared/api';


const fetcher = async () => {
  const { data } = await api.get(`${baseEndpointV1}/banners/list/`);
  return data;
};


const useBanner = () => {
  const { data: banner, refetch: mutate, error } = useQuery({
    queryKey: ['banners-list'],
    queryFn: fetcher,
  });

  const createBanner = useCallback(
    async (bannerImage, bannerUrl) => {
      const formData = new FormData();
      formData.append('banner_image', bannerImage);
      formData.append('banner_url', bannerUrl);

      await api.post(`${baseEndpointV1}/banners/create/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      mutate();
    },
    [mutate],
  );

  const deleteBanner = useCallback(
    async (id) => {
      await api.delete(`${baseEndpointV1}/banners/update_or_delete/${id}/`);
      mutate();
    },
    [mutate],
  );

  const updateBanner = useCallback(
    async (id, bannerImage, bannerUrl) => {
      const formData = new FormData();
      if (bannerImage instanceof File) {
        formData.append('banner_image', bannerImage);
      }
      formData.append('banner_url', bannerUrl);

      await api.patch(
        `${baseEndpointV1}/banners/update_or_delete/${id}/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      mutate();
    },
    [mutate],
  );

  return {
    banner: banner || [],
    isLoading: !banner && !error,
    isError: error,
    createBanner,
    deleteBanner,
    updateBanner,
  };
};

export default useBanner;
