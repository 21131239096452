import { GetServerSidePropsContext } from 'next';
import { IndexPage } from '@/modules/index-page';
import { pageFactory } from '@/modules/app/components';
import { EventsApi } from '@/modules/events/api';
import { IEvent } from '@/modules/events';

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const { req, res } = context;
  const { cookies } = req;
  const cityId: string = cookies.selectedCity || 'allcities';

  const filters = cityId !== 'allcities' ? { city: cityId } : '';

  if (cityId === 'allcities') {
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1); // срок жизни 1 год

    res.setHeader(
      'Set-Cookie',
      `selectedCity=allcities; Path=/; Expires=${expires.toUTCString()}; Secure=${process.env.NODE_ENV !== 'development'}`,
    );
  }

  try {
    const { results: events } = await EventsApi.list({
      page: 1,
      pageSize: 5,
      order: ['event_date', 'event_time'],
      filters,
    });

    if (!events) {
      throw new Error('Events not found');
    }

    return {
      props: {
        events,
      },
    };
  } catch (e) {
    return {
      props: {
        events: [],
      },
    };
  }
}

export default pageFactory<{ events: IEvent[] | [] }>(IndexPage);
